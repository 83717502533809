import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Update the page title based on the current location
 */
const usePageTitle = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    const baseTitle = 'Eco-Gagnant'

    const titleMap: { [route: string]: string } = {
      '/electricity': 'Conso électrique',
      '/water': "Conso d'eau",
      '/gas': 'Conso de gaz',
      '/consumption': 'Conso',
      '/ecogesture-selection': 'Sélection des astuces',
      '/options/legalnotice': 'Mentions Légales',
      '/options/gcu': "Conditions Générales d'Utilisation",
      '/options': 'Options',
      '/analysis': 'Analyse',
      '/profileType': 'Ajuster mon profil',
      '/challenges': 'Défis',
      '/ecogesture': 'Astuces',
      '/programme-eco-gagnant': 'Programme Eco-Gagnant',
      '/notifications': 'Notifs',
    }

    let title = ''

    for (const [key, value] of Object.entries(titleMap)) {
      if (pathname.includes(key)) {
        title = value
        break
      }
    }

    if (title) {
      document.title = `${title} | ${baseTitle}`
    } else {
      document.title = baseTitle
    }
  }, [pathname])
}

export default usePageTitle
