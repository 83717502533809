import { useTranslation } from 'react-i18next';

// assets
import Orange from 'assets/png/ecoGagnantProgram/orange.png';
import Pink from 'assets/png/ecoGagnantProgram/pink.png';
import Cyan from 'assets/png/ecoGagnantProgram/cyan.png';
import Yellow from 'assets/png/ecoGagnantProgram/yellow.png';
// components
import Content from 'components/Content/Content';
import 'components/EcoGagnantProgramView/ecoGagnantProgramView.scss';

const EcoGagnantProgramView = () => {
  const { t } = useTranslation();

  const buttonsConfig = [
    {
      img: Orange,
      text: t('ecoGagnantProgram.buttons.throwLess'),
      link: 'https://www.communaute-urbaine-dunkerque.fr/je-jette-moins-astuces',
    },
    {
      img: Cyan,
      text: t('ecoGagnantProgram.buttons.handleWater'),
      link: 'https://www.communaute-urbaine-dunkerque.fr/vie-pratique/le-cycle-de-leau/recupeau',
    },
    {
      img: Pink,
      text: t('ecoGagnantProgram.buttons.move'),
      link: 'https://www.communaute-urbaine-dunkerque.fr/aide-a-lacquisition-dun-velo',
    },
    {
      img: Yellow,
      text: t('ecoGagnantProgram.buttons.house'),
      link: 'https://www.communaute-urbaine-dunkerque.fr/vie-pratique/je-decouvre-laccompagnement-eco-gagnant',
    },
  ];

  return (
    <Content withOverFlow>
      <div className="eco-gagnant-program-content">
        <div className="title-wrapper">
          <h1>{t('ecoGagnantProgram.title')}<br/>Éco-Gagnant</h1>
          <div className="separator"></div>
          <h2>{t('ecoGagnantProgram.subtitle')}</h2>
        </div>

        <div className="buttons-wrapper">
          {buttonsConfig.map(({ img, link, text }) => (
            <a
              key={text}
              className="button"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={img} alt={text} />
              <span>{text}</span>
            </a>
          ))}
        </div>

        <div className="helper-text">{t('ecoGagnantProgram.helper')}</div>
      </div>
    </Content>
  );
};

export default EcoGagnantProgramView;
