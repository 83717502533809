import Content from 'components/Content/Content';
import Header from 'components/Header/Header';
import { UserChallengeState } from 'enums';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import ChallengeCard from './ChallengeCard/ChallengeCard';
import './challengeView.scss';

const ChallengeView = () => {
  const { userChallengeList, currentChallenge } = useAppSelector(
    (state) => state.challenge
  );
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [isLastDuelDone, setIsLastDuelDone] = useState<boolean>(false);
  const refCurrentChallengeWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      userChallengeList[userChallengeList.length - 1]?.state ==
      UserChallengeState.DONE
    ) {
      setIsLastDuelDone(true);
    }
  }, [userChallengeList]);

  const focusOnCurrentChallenge = () => {
    if (!refCurrentChallengeWrapper.current) return;

    refCurrentChallengeWrapper.current.scrollIntoView({
      block: 'center',
      inline: 'center',
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      focusOnCurrentChallenge();
    }, 1);

    return () => clearTimeout(timeout);
  }, [
    headerHeight,
    refCurrentChallengeWrapper,
    userChallengeList,
    currentChallenge,
  ]);

  return (
    <>
      <Content heightOffset={headerHeight}>
        <div className="challengeSlider">
          <div className="challenge-container">
            {userChallengeList.map((challenge, i) => (
              <div
                key={challenge.id}
                ref={
                  challenge.id === currentChallenge?.id
                    ? refCurrentChallengeWrapper
                    : undefined
                }
              >
                <ChallengeCard challenge={challenge} />
              </div>
            ))}

            {isLastDuelDone && (
              <div>
                <ChallengeCard isChallengeCardLast={true} />
              </div>
            )}
          </div>
        </div>
      </Content>
    </>
  );
};

export default ChallengeView;
