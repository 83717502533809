import { Button } from '@mui/material';
import defaultChallengeIcon from 'assets/icons/visu/challenge/CHALLENGE0001.svg';
import circleChecked from 'assets/icons/visu/challenge/circleChecked.svg';
import circleUnchecked from 'assets/icons/visu/challenge/circleUnchecked.svg';
import circleStar from 'assets/icons/visu/duel/circleStar.svg';
import defaultIcon from 'assets/icons/visu/duel/default.svg';
import lockedDuel from 'assets/icons/visu/duel/locked.svg';
import classNames from 'classnames';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import StarsContainer from 'components/CommonKit/StarsContainer/StarsContainer';
import Loader from 'components/Loader/Loader';

import {
  UserActionState,
  UserChallengeUpdateFlag,
  UserDuelState,
  UserExplorationState,
  UserQuizState,
} from 'enums';
import { UserChallenge } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateUserChallengeList } from 'store/challenge/challenge.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getChallengeTitleWithLineReturn, importIconById } from 'utils/utils';
import ChallengeNoFluidModal from '../ChallengeNoFluidModal/ChallengeNoFluidModal';
import './challengeCardOnGoing.scss';
import { getChallengeIconId } from 'utils/ecoGagnant';
import AppAxiosService from 'services/appAxios.service';
import ChallengeService from 'services/challenge.service';

const ChallengeCardOnGoing = ({
  userChallenge,
}: {
  userChallenge: UserChallenge;
}) => {
  const { t } = useTranslation();
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((store) => store.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const clientAxios = useMemo(() => new AppAxiosService(), []);
  const challengeService = useMemo(() => {
    if (!userChallengeProgress) return;
    if (!userSub) return;
    return new ChallengeService(
      clientAxios,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
  }, [userChallengeProgress, userSub]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentDataload } = useAppSelector((state) => state.challenge);
  const { fluidTypes, fluidStatus } = useAppSelector((state) => state.global);
  const [isOneFluidUp, setIsOneFluidUp] = useState<boolean>(true);
  const [challengeIcon, setChallengeIcon] = useState<string>(defaultIcon);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    progress: { actionProgress, explorationProgress, quizProgress },
    target,
    duel,
  } = userChallenge;

  const toggleNoFluidModal = useCallback(() => {
    setIsOneFluidUp((prev) => !prev);
  }, []);

  const goDuel = async () => {
    if (!challengeService) return;

    setIsLoading(true);
    if (fluidTypes.length !== 0) {
      if (duel.state !== UserDuelState.ONGOING) {
        const updatedChallenge = await challengeService.updateUserChallenge(
          userChallenge,
          UserChallengeUpdateFlag.DUEL_UPDATE_THRESHOLD,
          undefined,
          fluidStatus
        );
        dispatch(updateUserChallengeList(updatedChallenge));
      }
      setIsLoading(false);
      navigate('/challenges/duel?id=' + userChallenge.id);
    } else {
      setIsLoading(false);
      toggleNoFluidModal();
    }
  };

  const goQuiz = async () => {
    if (fluidTypes.length === 0) {
      toggleNoFluidModal();
      return;
    }

    if (!challengeService) return;

    navigate('/challenges/quiz');

    if (userChallenge.quiz.state !== UserQuizState.ONGOING) {
      const updatedChallenge = await challengeService.updateUserChallenge(
        userChallenge,
        UserChallengeUpdateFlag.QUIZ_RESET
      );
      dispatch(updateUserChallengeList(updatedChallenge));
    }
    if (userChallenge.progress.quizProgress !== 5) navigate('/challenges/quiz');
  };

  const goExploration = () => {
    if (userChallenge.progress.explorationProgress !== 5)
      navigate('/challenges/exploration');
  };

  const goAction = () => {
    if (userChallenge.progress.actionProgress !== 5)
      navigate('/challenges/action');
  };

  useEffect(() => {
    const importIcon = async () => {
      const icon = await importIconById(
        getChallengeIconId(userChallenge),
        'challenge'
      );
      setChallengeIcon(icon ?? defaultChallengeIcon);
    };

    importIcon();
  }, [userChallenge]);

  useEffect(() => {
    if (!challengeService) return;

    let subscribed = true;
    async function setChallengeResult() {
      if (!challengeService) return;

      const isChallengeDone = await challengeService.isChallengeDone(
        userChallenge,
        currentDataload
      );
      if (subscribed) {
        setIsDone(isChallengeDone.isDone);
      }
    }

    const unlockDuel = async () => {
      if (
        userChallenge.duel.state === UserDuelState.LOCKED &&
        userChallenge.progress.actionProgress === 5 &&
        userChallenge.progress.quizProgress === 5 &&
        userChallenge.progress.explorationProgress === 5
      ) {
        const updatedChallenge = await challengeService.updateUserChallenge(
          {
            ...userChallenge,
            progress: {
              ...userChallenge.progress,
              explorationProgress: 5,
              actionProgress: 5,
            },
          },
          UserChallengeUpdateFlag.DUEL_UNLOCK
        );
        dispatch(updateUserChallengeList(updatedChallenge));
      }
    };

    unlockDuel();
    setChallengeResult();
    return () => {
      subscribed = false;
    };
  }, [currentDataload, userChallenge, dispatch, challengeService]);

  const quizButton = () => (
    <Button
      title={t('challenge.card.ongoing.quiz')}
      tabIndex={userChallenge.progress.quizProgress === 5 ? -1 : 0}
      className={classNames('smallCard', {
        ['finished']: userChallenge.progress.quizProgress === 5,
      })}
      onClick={goQuiz}
    >
      <span className="MuiButton-label">
        <StyledIcon
          className="cardIcon"
          icon={
            userChallenge.progress.quizProgress === 5
              ? circleChecked
              : circleUnchecked
          }
          size={25}
        />
        <div className="content">
          <span>{t('challenge.card.ongoing.quiz')}</span>
          <StarsContainer result={userChallenge.progress.quizProgress} />
        </div>
      </span>
    </Button>
  );

  const explorationButton = () => (
    <Button
      title={t('challenge.card.ongoing.exploration')}
      tabIndex={userChallenge.progress.explorationProgress === 5 ? -1 : 0}
      className={classNames('smallCard explorationCard', {
        ['finished']: userChallenge.progress.explorationProgress === 5,
      })}
      onClick={goExploration}
    >
      <span className="MuiButton-label">
        <StyledIcon
          className="cardIcon"
          icon={
            userChallenge.progress.explorationProgress === 5
              ? circleChecked
              : circleUnchecked
          }
          size={25}
        />
        {userChallenge.exploration.state ===
          UserExplorationState.NOTIFICATION && (
          <div className="notifChallenge">1</div>
        )}
        <div className="content">
          <span>{t('challenge.card.ongoing.exploration')}</span>
          <StarsContainer result={userChallenge.progress.explorationProgress} />
        </div>
      </span>
    </Button>
  );

  const actionButton = () => (
    <Button
      title={t('challenge.card.ongoing.action')}
      tabIndex={userChallenge.progress.actionProgress === 5 ? -1 : 0}
      className={classNames('smallCard actionCard', {
        ['finished']: userChallenge.progress.actionProgress === 5,
      })}
      onClick={goAction}
    >
      <span className="MuiButton-label">
        <StyledIcon
          className="cardIcon"
          icon={
            userChallenge.progress.actionProgress === 5
              ? circleChecked
              : circleUnchecked
          }
          size={25}
        />
        {userChallenge.action.state === UserActionState.NOTIFICATION && (
          <div className="notifChallenge">1</div>
        )}
        <div className="content">
          <span>{t('challenge.card.ongoing.action')}</span>
          <StarsContainer result={userChallenge.progress.actionProgress} />
        </div>
      </span>
    </Button>
  );

  const duelButton = (
    <Button
      className="smallCard goDuel"
      onClick={goDuel}
      endIcon={
        <StyledIcon
          className="challengeminIcon"
          icon={challengeIcon}
          height={65}
        />
      }
    >
      {isLoading ? (
        <div className="spinner-container">
          <Loader color="black" />
        </div>
      ) : (
        <span>{t('challenge.card.ongoing.duel')}</span>
      )}
    </Button>
  );

  const duelCard = (content: JSX.Element, extraClassName = '') => (
    <Button className={`smallCard duelCard ${extraClassName}`} onClick={goDuel}>
      <span className="MuiButton-label">{content}</span>
      <StyledIcon className="circleStar" icon={challengeIcon} width={60} />
    </Button>
  );

  const duelContainer = () => {
    if (
      duel.state === UserDuelState.NO_REF_PERIOD_VALID ||
      (actionProgress + explorationProgress + quizProgress >= target &&
        duel.state === UserDuelState.UNLOCKED)
    ) {
      return duelButton;
    } else if (duel.state === UserDuelState.ONGOING && !isDone) {
      return duelCard(
        <div className="finalDuel">
          <span>{t('challenge.card.ongoing.duel')}</span>
          <p className="starCount">
            <span className="blueNumber">{`${duel.userConsumption} €  `}</span>
            <span>{` / ${duel.threshold} €`}</span>
          </p>
        </div>,
        'active'
      );
    } else if (duel.state === UserDuelState.ONGOING && isDone) {
      return duelCard(
        <>
          <div className="finalDuel result">
            <span>{t('challenge.card.ongoing.result')}</span>
            <span>{t('challenge.card.ongoing.duelDone')}</span>
          </div>
          <div className="notifChallenge">1</div>
        </>,
        'active'
      );
    } else {
      return (
        <Button className="smallCard duelCard duelLocked" disabled>
          <span className="MuiButton-label duelButton">
            <div className="starCount">
              <StyledIcon icon={circleStar} width={30} />
              <span className="blueNumber">
                {quizProgress + explorationProgress + actionProgress}
              </span>
              <span>{` / ${target}`}</span>
            </div>
            <StyledIcon className="circleStar" icon={lockedDuel} width={60} />
          </span>
        </Button>
      );
    }
  };

  return (
    <div className="cardContent onGoing">
      <div className="titleBlock">
        <span className="challengeTitle">
          {getChallengeTitleWithLineReturn(getChallengeIconId(userChallenge))}
        </span>
      </div>
      {quizButton()}
      {explorationButton()}
      {actionButton()}
      {duelContainer()}
      <ChallengeNoFluidModal
        open={!isOneFluidUp}
        handleCloseClick={toggleNoFluidModal}
      />
    </div>
  );
};

export default ChallengeCardOnGoing;
