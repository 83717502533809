import ActionView from 'components/Action/ActionView';
import ChallengeView from 'components/Challenge/ChallengeView';
import SgeConnectView from 'components/Connection/SGEConnect/SgeConnectView';
import DuelView from 'components/Duel/DuelView';
import EcoGagnantProgramView from 'components/EcoGagnantProgramView/EcoGagnantProgramView';
import EcogestureFormView from 'components/EcogestureForm/EcogestureFormView';
import EcogestureSelectionView from 'components/EcogestureSelection/EcogestureSelectionView';
import ExplorationView from 'components/Exploration/ExplorationView';
import GeoDataView from 'components/GeoDataView/GeoDataView';
import Loader from 'components/Loader/Loader';
import NotificationsView from 'components/NotificationsView/NotificationsView';
import UnSubscribeView from 'components/Options/Unsubscribe/UnSubscribeView';
import QuizView from 'components/Quiz/QuizView';
import TermsView from 'components/Terms/TermsView';
import { FluidType } from 'enums';
import { TermsStatus } from 'models';
import React, { lazy, Suspense } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, Route, Routes } from 'react-router-dom';

const ConsumptionView = lazy(
  () => import('components/Consumption/ConsumptionView')
);
const EcogestureTabsView = lazy(
  () => import('components/Ecogesture/EcogestureTabsView')
);
const SingleEcogestureView = lazy(
  () => import('components/Ecogesture/SingleEcogestureView')
);
const OptionsView = lazy(() => import('components/Options/OptionsView'));
const LegalNoticeView = lazy(
  () => import('components/Options/LegalNotice/LegalNoticeView')
);
const GCUView = lazy(() => import('components/Options/GCU/GCUView'));
const AnalysisView = lazy(() => import('components/Analysis/AnalysisView'));
const ProfileTypeView = lazy(
  () => import('components/ProfileType/ProfileTypeView')
);

const AppRoutes = () => {
  const auth = useAuth();

  return (
    <Suspense
      fallback={
        <div className="loaderContainer">
          <Loader />
        </div>
      }
    >
      <Routes>
        {auth.isAuthenticated && (
          <>
            <Route
              path="/consumption/electricity"
              element={<ConsumptionView fluidType={FluidType.ELECTRICITY} />}
            />
            <Route
              path="/consumption/water"
              element={<ConsumptionView fluidType={FluidType.WATER} />}
            />
            <Route
              path="/consumption/gas"
              element={<ConsumptionView fluidType={FluidType.GAS} />}
            />
            <Route
              path="/consumption"
              element={<ConsumptionView fluidType={FluidType.MULTIFLUID} />}
            />
            <Route path="/sge-connect" element={<SgeConnectView />} />
            <Route path="/challenges/duel" element={<DuelView />} />
            <Route path="/challenges/quiz" element={<QuizView />} />
            <Route
              path="/challenges/exploration"
              element={<ExplorationView />}
            />
            <Route path="/challenges/action" element={<ActionView />} />
            <Route path="/challenges/" element={<ChallengeView />} />
            <Route path="/ecogesture-form" element={<EcogestureFormView />} />
            <Route
              path="/ecogesture-selection"
              element={<EcogestureSelectionView />}
            />
            <Route
              path="/ecogesture/:ecogestureID"
              element={<SingleEcogestureView />}
            />
            {/* Geodata feature on standby */}
            {/* <Route path="/geo-data" element={<GeoDataView />} /> */}
            <Route
              path="/programme-eco-gagnant"
              element={<EcoGagnantProgramView />}
            />
            <Route path="/notifications" element={<NotificationsView />} />
            <Route path="/ecogestures" element={<EcogestureTabsView />} />
            <Route path="/options/legalnotice" element={<LegalNoticeView />} />
            <Route path="/options/gcu" element={<GCUView />} />
            <Route path="/options/:connectParam" element={<OptionsView />} />
            <Route path="/options" element={<OptionsView />} />
            <Route path="/analysis" element={<AnalysisView />} />
            <Route path="/profiletype" element={<ProfileTypeView />} />
            <Route path="/unsubscribe" element={<UnSubscribeView />} />
            <Route path="*" element={<Navigate replace to="/consumption" />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
