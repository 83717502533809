import { Ecogesture, UserChallenge } from 'models';
import React, { useState } from 'react';
import ActionBegin from '../ActionBegin/ActionBegin';
import ActionList from '../ActionList/ActionList';
import './actionChoose.scss';

const ActionChoose = ({
  userChallenge,
  setFocus,
}: {
  userChallenge: UserChallenge;
  setFocus: () => void;
}) => {
  const [selectedAction, setSelectedAction] = useState<Ecogesture | null>(null);
  const [showList, setShowList] = useState<boolean>(false);

  return (
    <div className="action-content-view">
      {!showList ? (
        <ActionBegin
          action={selectedAction ?? undefined}
          setShowList={setShowList}
          userChallenge={userChallenge}
          setFocus={setFocus}
        />
      ) : (
        <ActionList
          setSelectedAction={setSelectedAction}
          setShowList={setShowList}
          setFocus={setFocus}
        />
      )}
    </div>
  );
};

export default ActionChoose;
