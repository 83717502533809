import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from 'assets/icons/ico/close.svg';
import ecolyoIcon from 'assets/icons/ico/ecolyo.svg';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import useExploration from 'hooks/useExploration';
import { useTranslation } from 'react-i18next';
import { UserExplorationID } from 'enums';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openFeedbackModal } from 'store/modal/modal.slice';
import './feedbackModal.scss';
import packageJson from 'components/../../package.json';

const FeedbackModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isFeedbacksOpen = useAppSelector(
    (state) => state.modal.isFeedbacksOpen
  );
  const userId = useAppSelector((store) => store.profileStellioUser.id);
  const [, setValidExploration] = useExploration();

  const closeModal = () => {
    dispatch(openFeedbackModal(false));
  };

  const goToSAU = () => {
    setValidExploration(UserExplorationID.EXPLORATION005);
    const subject = '[ECO-GAGNANT] Retour utilisateur';
    const body = `Bonjour l'équipe Eco-Gagnant !
    
    ... 


    --------------------
    Ne pas supprimer les informations ci-dessous: 
    v: ${packageJson.version}
    id: ${userId}
    --------------------
    
    `;
    const recipients = 'eco-gagnant@cud.fr';
    const url = `mailto:${recipients}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.assign(url);
  };

  return (
    <Dialog
      open={isFeedbacksOpen}
      onClose={() => closeModal()}
      aria-labelledby="accessibility-title"
      classes={{
        root: 'modal-root',
        paper: 'modal-paper yellow-border',
      }}
    >
      <div id="accessibility-title">
        {t('feedback.accessibility.window_title')}
      </div>
      <IconButton
        aria-label={t('feedback.accessibility.button_close')}
        className="modal-paper-close-button"
        onClick={closeModal}
      >
        <StyledIcon icon={CloseIcon} width={16} />
      </IconButton>
      <div className="fb-root">
        <StyledIcon icon={ecolyoIcon} width={80} />
        <p className="title">{t('feedback.title')}</p>
        <p className="text">{t('feedback.text1')}</p>
        <p className="text">{t('feedback.text2')}</p>
        <div className="actions">
          <Button
            aria-label={t('feedback.later')}
            onClick={closeModal}
            variant="outlined"
          >
            {t('feedback.later')}
          </Button>
          <Button
            aria-label={t('feedback.lets_go')}
            onClick={goToSAU}
            variant="contained"
          >
            {t('feedback.lets_go')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default FeedbackModal;
