import Button from '@mui/material/Button';
import defaultIcon from 'assets/icons/visu/ecogesture/default.svg';
import ActionModal from 'components/Action/ActionModal/ActionModal';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import StarsContainer from 'components/CommonKit/StarsContainer/StarsContainer';
import { Ecogesture, UserChallenge } from 'models';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { importIconById } from 'utils/utils';
import './actionBegin.scss';
import { useTranslation } from 'react-i18next';
import ActionService from 'services/action.service';
import AppAxiosService from 'services/appAxios.service';
import { getEcogestureIconId } from 'utils/ecoGagnant';

interface ActionBeginProps {
  action?: Ecogesture;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  userChallenge: UserChallenge;
  setFocus: () => void;
}

const ActionBegin = ({
  action,
  setShowList,
  userChallenge,
  setFocus,
}: ActionBeginProps) => {
  const { t } = useTranslation();
  const { fluidTypes } = useAppSelector((state) => state.global);
  const { isProfileTypeCompleted } = useAppSelector((state) => state.profile);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((state) => state.challenge);
  const [currentAction, setCurrentAction] = useState<Ecogesture>();
  const [actionIcon, setActionIcon] = useState<string>('');
  const [openLaunchModal, setOpenLaunchModal] = useState<boolean>(false);
  const client = useMemo(() => new AppAxiosService(), []);

  const toggleLaunchModal = useCallback(() => {
    setOpenLaunchModal((prev) => !prev);
  }, []);

  useEffect(() => {
    let subscribed = true;
    const getAction = async () => {
      if (action) {
        if (subscribed) setCurrentAction(action);
      } else {
        if (!userChallengeProgress) return;
        if (!userSub) return;

        const actionService = new ActionService(
          client,
          userSub,
          userChallengeProgress,
          ecogesturesList,
          explorationsList
        );

        let actions: Ecogesture[] = [];
        if (isProfileTypeCompleted) {
          actions = await actionService.getCustomActions(fluidTypes);
        } else {
          actions = await actionService.getDefaultActions();
        }
        if (subscribed) {
          setCurrentAction(actions[0]);
        }
      }
    };
    getAction();
    return () => {
      subscribed = false;
    };
  }, [
    action,
    client,
    ecogesturesList,
    explorationsList,
    fluidTypes,
    isProfileTypeCompleted,
    userChallengeProgress,
    userSub,
  ]);

  useEffect(() => {
    async function handleEcogestureIcon() {
      if (currentAction) {
        const icon = await importIconById(
          getEcogestureIconId(currentAction),
          'ecogesture'
        );
        setActionIcon(icon || defaultIcon);
      }
    }
    handleEcogestureIcon();
  }, [currentAction]);

  return (
    <div className="action-begin">
      {currentAction && (
        <div className="action-container">
          <div className="action-begin-container">
            <div className="icon-container">
              <StyledIcon
                className="action-icon"
                icon={actionIcon}
                width={100}
              />
            </div>
            <StarsContainer
              result={userChallenge.progress.actionProgress}
              isQuizBegin={true}
            />
            <div className="action-title text-20-bold">
              {currentAction.shortName}
            </div>
            <div className="action-duration text-18">
              {t('action.duration', {
                smartCount: currentAction.actionDuration,
              })}
            </div>
            <div className="action-text text-18-bold">
              {currentAction.actionName}
            </div>
            <div className="action-buttons">
              <Button
                variant="contained"
                onClick={toggleLaunchModal}
                className="text-16-normal"
              >
                {t('action.apply')}
              </Button>
              <Button variant="outlined" onClick={() => setShowList(true)}>
                {t('action.other')}
              </Button>
            </div>
          </div>
          <ActionModal
            open={openLaunchModal}
            action={currentAction}
            handleCloseClick={toggleLaunchModal}
            userChallenge={userChallenge}
            setFocus={setFocus}
          />
        </div>
      )}
    </div>
  );
};

export default ActionBegin;
