import { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// assets
import ElecSmallIcon from 'assets/icons/visu/elec-small.svg';
import GasSmallIcon from 'assets/icons/visu/gas-small.svg';
import WaterSmallIcon from 'assets/icons/visu/water-small.svg';
import Multi from 'assets/icons/visu/multi.svg';
// components
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import 'components/NotificationsView/FluidSelector/fluidSelector.scss';
// enums
import { FluidType } from 'enums';

interface Props {
  onSelect?: (fluidType: FluidType) => void;
  canSelectMultiFluid?: boolean;
  defaultValue?: FluidType;
  disabledFluids?: FluidType[];
}

const FluidSelector = ({
  onSelect,
  canSelectMultiFluid,
  defaultValue,
  disabledFluids,
}: Props) => {
  const [fluidType, setFluidType] = useState(
    defaultValue ?? FluidType.MULTIFLUID
  );

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newFluidType: FluidType
  ) => {
    if (newFluidType !== null) {
      setFluidType(newFluidType);
      if (onSelect) onSelect(newFluidType);
    }
  };

  const checkIsDisabled = (fluidType: FluidType) =>
    disabledFluids ? disabledFluids.includes(fluidType) : false;

  const children = [
    <ToggleButton value={FluidType.MULTIFLUID} key={FluidType.MULTIFLUID}>
      <StyledIcon className="fluid-icon" icon={Multi} width={32} />
    </ToggleButton>,
    <ToggleButton
      value={FluidType.ELECTRICITY}
      disabled={checkIsDisabled(FluidType.ELECTRICITY)}
      key={FluidType.ELECTRICITY}
    >
      <StyledIcon
        className={`fluid-icon ${checkIsDisabled(FluidType.ELECTRICITY) ? 'disabled' : ''}`}
        icon={ElecSmallIcon}
        width={32}
      />
      {checkIsDisabled(FluidType.ELECTRICITY) && <div className="red-strike" />}
    </ToggleButton>,
    <ToggleButton
      value={FluidType.WATER}
      disabled={checkIsDisabled(FluidType.WATER)}
      key={FluidType.WATER}
    >
      <StyledIcon
        className={`fluid-icon ${checkIsDisabled(FluidType.WATER) ? 'disabled' : ''}`}
        icon={WaterSmallIcon}
        width={32}
      />
      {checkIsDisabled(FluidType.WATER) && <div className="red-strike" />}
    </ToggleButton>,
    <ToggleButton
      value={FluidType.GAS}
      disabled={checkIsDisabled(FluidType.GAS)}
      key={FluidType.GAS}
    >
      <StyledIcon
        className={`fluid-icon ${checkIsDisabled(FluidType.GAS) ? 'disabled' : ''}`}
        icon={GasSmallIcon}
        width={32}
      />
      {checkIsDisabled(FluidType.GAS) && <div className="red-strike" />}
    </ToggleButton>,
  ];

  const control = {
    value: fluidType,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <ToggleButtonGroup
      size="medium"
      color="primary"
      {...control}
      aria-label="Fluid type selector"
      sx={{
        '& .MuiToggleButton-root.Mui-selected': {
          backgroundColor: 'rgba(227, 184, 42, 0.2)',
        },
      }}
    >
      {children.filter((child, i) => (!canSelectMultiFluid ? i !== 0 : true))}
    </ToggleButtonGroup>
  );
};

export default FluidSelector;
