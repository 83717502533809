import Button from '@mui/material/Button';
import CloseIcon from 'assets/icons/ico/close.svg';
import StyledIconButton from 'components/CommonKit/IconButton/StyledIconButton';
import QuizExplanationModal from 'components/Quiz/QuizExplanationModal/QuizExplanationModal';
import { useTranslation } from 'react-i18next';
import { UserChallengeUpdateFlag } from 'enums';
import { Answer, UserChallenge, UserQuiz } from 'models';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import ChallengeService from 'services/challenge.service';
import QuizService from 'services/quiz.service';
import {
  setCurrentUserChallenge,
  updateUserChallengeList,
} from 'store/challenge/challenge.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import './quizQuestion.scss';
import AppAxiosService from 'services/appAxios.service';

interface QuizQuestionContentProps {
  userChallenge: UserChallenge;
  setIsCustomQuest: Dispatch<SetStateAction<boolean>>;
  goBack: () => void;
  focusCallback: () => void;
}

const QuizQuestionContent = ({
  userChallenge,
  setIsCustomQuest,
  goBack,
  focusCallback,
}: QuizQuestionContentProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const clientAxios = new AppAxiosService();
  const { userChallengeProgress, ecogesturesList, explorationsList } =
    useAppSelector((store) => store.challenge);
  const userSub = useAppSelector((store) => store.profileKeycloakUser.sub);
  const questionIndexLocked = userChallenge.quiz.questions.findIndex(
    (answer) => answer.result == 0
  );
  const [userChoice, setUserChoice] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [answerIndex, setAnswerIndex] = useState<number>(0);
  const [questionIndex, setQuestionIndex] =
    useState<number>(questionIndexLocked);

  const challengeService: ChallengeService | undefined = useMemo(() => {
    if (!userChallengeProgress) return;
    if (!userSub) return;
    return new ChallengeService(
      clientAxios,
      userSub,
      userChallengeProgress,
      ecogesturesList,
      explorationsList
    );
  }, [userChallengeProgress]);

  const validateQuestion = async () => {
    if (!challengeService) return;
    if (!userSub) return;

    const resultIndex: number = userChallenge.quiz.questions[
      questionIndex
    ].answers.findIndex((answer) => answer.answerLabel === userChoice);
    const result: Answer[] = userChallenge.quiz.questions[
      questionIndex
    ].answers.filter((answer) => answer.answerLabel === userChoice);
    setAnswerIndex(resultIndex);
    setOpenModal(true);

    const quizService: QuizService = new QuizService(clientAxios, userSub);
    const quizUpdated: UserQuiz = await quizService.updateUserQuiz(
      userChallenge.quiz,
      result[0].isTrue,
      questionIndex
    );
    const userChallengeUpdated: UserChallenge =
      await challengeService.updateUserChallenge(
        userChallenge,
        UserChallengeUpdateFlag.QUIZ_UPDATE,
        quizUpdated
      );
    dispatch(updateUserChallengeList(userChallengeUpdated));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserChoice(e.target.value);
  };

  const goNextQuestion = useCallback(() => {
    setUserChoice('');
    setOpenModal(false);
    if (questionIndex === userChallenge.quiz.questions.length - 1) {
      setIsCustomQuest(true);
    }
    if (questionIndex !== userChallenge.quiz.questions.length - 1) {
      setQuestionIndex(questionIndex + 1);
    }
    focusCallback();
  }, [
    questionIndex,
    setIsCustomQuest,
    focusCallback,
    setQuestionIndex,
    setUserChoice,
    setOpenModal,
    userChallenge.quiz.questions.length,
  ]);

  return (
    <div className="quiz-container">
      <div className="question-container">
        <StyledIconButton
          className="btn-back"
          icon={CloseIcon}
          onClick={goBack}
          aria-label={t('quiz.accessibility.window_quit')}
        />
        <p className="index-question">{questionIndex + 1}/5</p>
        <p className="question text-18-bold">
          {userChallenge.quiz.questions[questionIndex].questionLabel}
        </p>
        {userChallenge.quiz.questions[questionIndex].answers.map(
          (answer, index) => (
            <div className="answer" key={index}>
              <input
                type="radio"
                id={`answer${index}`}
                value={answer.answerLabel}
                onChange={handleChange}
                checked={userChoice === answer.answerLabel}
              />
              <label htmlFor={`answer${index}`} className="text-16-bold">
                {answer.answerLabel}
              </label>
            </div>
          )
        )}
      </div>
      <br />
      <Button
        variant="contained"
        aria-label={t('quiz.accessibility.button_validate')}
        onClick={validateQuestion}
        disabled={!userChoice}
      >
        {t('quiz.button_validate')}
      </Button>
      {openModal && (
        <QuizExplanationModal
          open={openModal}
          answerIndex={answerIndex}
          question={userChallenge.quiz.questions[questionIndex]}
          goNext={goNextQuestion}
          handleCloseClick={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

export default QuizQuestionContent;
