import { useRef, useState } from 'react';
import Link from '@mui/material/Link';
import AnalysisIconOff from 'assets/icons/tabbar/analysis/analysis-off.svg';
import AnalysisIconOn from 'assets/icons/tabbar/analysis/analysis-on.svg';
import BulbIconOff from 'assets/icons/tabbar/astuces/astuces-off.svg';
import BulbIconOn from 'assets/icons/tabbar/astuces/astuces-on.svg';
import ConsoIconOff from 'assets/icons/tabbar/conso/conso-off.svg';
import ConsoIconOn from 'assets/icons/tabbar/conso/conso-on.svg';
import ChallengeIconOff from 'assets/icons/tabbar/defi/defi-off.svg';
import ChallengeIconOn from 'assets/icons/tabbar/defi/defi-on.svg';
import ParameterIconOff from 'assets/icons/tabbar/parametre/parametre-off.svg';
import ParameterIconOn from 'assets/icons/tabbar/parametre/parametre-on.svg';
import GeoDataOff from 'assets/icons/tabbar/geoData/geoData-off.svg';
import GeoDataOn from 'assets/icons/tabbar/geoData/geoData-on.svg';
import PlusOff from 'assets/icons/tabbar/plus/plus-off.svg';
import PlusOn from 'assets/icons/tabbar/plus/plus-on.svg';
import NotificationsOff from 'assets/icons/tabbar/notifications/notifications-off.svg';
import NotificationsOn from 'assets/icons/tabbar/notifications/notifications-on.svg';
import ProgEcoGagnantOff from 'assets/icons/tabbar/progEcoGagnant/progEcoGagnant-off.svg';
import ProgEcoGagnantOn from 'assets/icons/tabbar/progEcoGagnant/progEcoGagnant-on.svg';
import logos from 'assets/png/logo-eco-gagnant-clair.png';
import StyledIcon from 'components/CommonKit/Icon/StyledIcon';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import 'components/Navbar/navBar.scss';

const Navbar = () => {
  const { t } = useTranslation();
  const {
    challengeExplorationNotification,
    challengeActionNotification,
    challengeDuelNotification,
    analysisNotification,
  } = useAppSelector((state) => state.global);
  const { value: isProfileTypeCompleted } = useAppSelector(
    (state) => state.profileStellioUser.isProfileTypeCompleted
  );
  const { pathname } = useLocation();
  const [canShowSecondaryNavItems, setCanShowSecondaryNavItems] =
    useState(false);
  const asideNavRef = useRef<HTMLElement>(null);
  useOutsideAlerter(asideNavRef, () => setCanShowSecondaryNavItems(false));

  /** Return class "is-active" if pathname includes matcher */
  const isActive = (matcher: string) => {
    return pathname.includes(matcher) ? 'is-active' : '';
  };

  const PrimaryNavItems = (
    <ul className="c-nav">
      <li className="c-nav-item">
        <Link
          component={NavLink}
          to="/consumption"
          className={`c-nav-link ${isActive('/consumption')}`}
        >
          <StyledIcon className="c-nav-icon off" icon={ConsoIconOff} />
          <StyledIcon className="c-nav-icon on" icon={ConsoIconOn} />
          {t('navigation.consumption')}
        </Link>
      </li>
      <li className="c-nav-item">
        <Link
          component={NavLink}
          to="/challenges"
          className={`c-nav-link ${isActive('/challenges')}`}
        >
          {(challengeExplorationNotification ||
            challengeActionNotification ||
            challengeDuelNotification) && <div className="nb-notif">1</div>}
          <StyledIcon className="c-nav-icon off" icon={ChallengeIconOff} />
          <StyledIcon className="c-nav-icon on" icon={ChallengeIconOn} />
          {t('navigation.challenges')}
        </Link>
      </li>
      <li className="c-nav-item">
        <Link
          component={NavLink}
          to="/ecogestures"
          className={`c-nav-link ${isActive('/ecogesture')}`}
        >
          <StyledIcon className="c-nav-icon off" icon={BulbIconOff} />
          <StyledIcon className="c-nav-icon on" icon={BulbIconOn} />
          {t('navigation.ecogestures')}
        </Link>
      </li>
      <li className="c-nav-item">
        <Link
          component={NavLink}
          to="/analysis"
          className={`c-nav-link ${
            pathname === '/analysis' ? 'is-active' : ''
          }`}
        >
          {analysisNotification && <div className="nb-notif">1</div>}
          <StyledIcon className="c-nav-icon off" icon={AnalysisIconOff} />
          <StyledIcon className="c-nav-icon on" icon={AnalysisIconOn} />
          {t('navigation.analysis')}
        </Link>
      </li>
      <li id="show-more-item" className="c-nav-item">
        <div
          onClick={() => setCanShowSecondaryNavItems(!canShowSecondaryNavItems)}
          className={`c-nav-link ${canShowSecondaryNavItems ? 'is-active' : ''}`}
        >
          <StyledIcon className="c-nav-icon off shrink" icon={PlusOff} />
          <StyledIcon className="c-nav-icon on shrink" icon={PlusOn} />
          {t(`navigation.plus`)}
        </div>
      </li>
    </ul>
  );

  const SecondaryNavItems = (
    <ul
      className={`c-nav secondary-c-nav ${isProfileTypeCompleted ? 'profile-completed' : ''}`}
    >
      {isProfileTypeCompleted && (
        <>
          {/* Geodata feature on standby */}
          {/* <li className="c-nav-item">
            <Link
              component={NavLink}
              to="/geo-data"
              className={`c-nav-link ${isActive('/geo-data')}`}
            >
              <StyledIcon className="c-nav-icon off shrink" icon={GeoDataOff} />
              <StyledIcon className="c-nav-icon on shrink" icon={GeoDataOn} />
              {t('navigation.geoData')}
            </Link>
          </li> */}
          <li className="c-nav-item">
            <Link
              component={NavLink}
              to="/programme-eco-gagnant"
              className={`c-nav-link ${isActive('/programme-eco-gagnant')}`}
            >
              <StyledIcon
                className="c-nav-icon off shrink"
                icon={ProgEcoGagnantOff}
              />
              <StyledIcon
                className="c-nav-icon on shrink"
                icon={ProgEcoGagnantOn}
              />
              {t('navigation.eco_gagnant_program')}
            </Link>
          </li>
          <li className="c-nav-item">
            <Link
              component={NavLink}
              to="/notifications"
              className={`c-nav-link ${isActive('/notifications')}`}
            >
              <StyledIcon
                className="c-nav-icon off shrink"
                icon={NotificationsOff}
              />
              <StyledIcon
                className="c-nav-icon on shrink"
                icon={NotificationsOn}
              />
              {t('navigation.notifications')}
            </Link>
          </li>
        </>
      )}

      <li className="c-nav-item">
        <Link
          component={NavLink}
          to="/options"
          className={`c-nav-link ${isActive('/options')}`}
        >
          <StyledIcon className="c-nav-icon off" icon={ParameterIconOff} />
          <StyledIcon className="c-nav-icon on" icon={ParameterIconOn} />
          {t('navigation.options')}
        </Link>
      </li>
    </ul>
  );

  return (
    <aside
      ref={asideNavRef}
      className={`o-sidebar ${canShowSecondaryNavItems ? 'with-secondary-nav' : ''}`}
    >
      <nav
        role="navigation"
        aria-label="navigation"
        className="navigation-items-wrapper"
      >
        {isProfileTypeCompleted && PrimaryNavItems}
        {SecondaryNavItems}
      </nav>
    </aside>
  );
};

export default Navbar;
