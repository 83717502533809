import Content from 'components/Content/Content';
import Header from 'components/Header/Header';
import { UserActionState } from 'enums';
import { UserChallenge } from 'models';
import React, { useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import ActionChoose from './ActionChoose/ActionChoose';
import ActionDone from './ActionDone/ActionDone';
import ActionOnGoing from './ActionOnGoing/ActionOnGoing';

const ActionView = () => {
  const { currentChallenge } = useAppSelector((state) => state.challenge);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const mainContentRef = useRef<HTMLDivElement>(null);
  const focusMainContent = () => {
    setTimeout(() => mainContentRef.current?.focus(), 0);
  };

  const renderAction = (challenge: UserChallenge) => {
    switch (challenge.action.state) {
      case UserActionState.UNSTARTED:
        return (
          <ActionChoose userChallenge={challenge} setFocus={focusMainContent} />
        );
      case UserActionState.ONGOING:
        return <ActionOnGoing userAction={challenge.action} />;
      case UserActionState.NOTIFICATION:
        return <ActionDone currentChallenge={challenge} />;
      default:
        return (
          <ActionChoose userChallenge={challenge} setFocus={focusMainContent} />
        );
    }
  };

  return (
    <>
      <Header
        setHeaderHeight={setHeaderHeight}
        desktopTitleKey="common.title_action"
        displayBackArrow={true}
      />
      <Content heightOffset={headerHeight}>
        <div
          ref={mainContentRef}
          style={{ outline: 'none', margin: 'auto' }}
          tabIndex={-1}
        >
          {currentChallenge && renderAction(currentChallenge)}
        </div>
      </Content>
    </>
  );
};

export default ActionView;
