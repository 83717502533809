import classNames from 'classnames';
import FormNavigation from 'components/CommonKit/FormNavigation/FormNavigation';
import FormProgress from 'components/CommonKit/FormProgress/FormProgress';
import 'components/ProfileType/profileTypeForm.scss';
import { useTranslation } from 'react-i18next';
import { ProfileTypeStepForm } from 'enums';
import {
  ProfileEcogesture,
  ProfileType,
  ProfileTypeAnswer,
  ProfileTypeValues,
} from 'models';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import HelperText from '../HelperText/HelperText';

interface ProfileTypeFormSingleChoiceProps {
  step: ProfileTypeStepForm;
  viewedStep: ProfileTypeStepForm;
  currentProfileType: ProfileType;
  answerType: ProfileTypeAnswer;
  setNextStep: (_profileType: ProfileType) => void;
  setPreviousStep: () => void;
}

const ProfileTypeFormSingleChoice = ({
  step,
  viewedStep,
  currentProfileType,
  answerType,
  setNextStep,
  setPreviousStep,
}: ProfileTypeFormSingleChoiceProps) => {
  const { t } = useTranslation();
  const navigationRef = useRef<HTMLDivElement>(null);
  const { value: isProfileEcogestureCompleted } = useAppSelector(
    (state) => state.profileStellioUser.isProfileEcogestureCompleted
  );
  const { value: isProfileTypeCompleted } = useAppSelector(
    (state) => state.profileStellioUser.isProfileTypeCompleted
  );
  const profileEcogesture = useAppSelector((state) => state.profileEcogesture);
  const [answer, setAnswer] = useState<ProfileTypeValues>('');
  const handlePrevious = useCallback(() => {
    setPreviousStep();
  }, [setPreviousStep]);

  const handleNext = useCallback(() => {
    setNextStep({ ...currentProfileType, [answerType.attribute]: answer });
  }, [currentProfileType, setNextStep, answer, answerType.attribute]);

  useEffect(() => {
    // Set answer if profileType is completed
    if (step < viewedStep || isProfileTypeCompleted) {
      setAnswer(currentProfileType[answerType.attribute]);
      return;
    }
    // Set answer if ecogestureProfile is completed
    if (isProfileEcogestureCompleted) {
      setAnswer(
        profileEcogesture[answerType.attribute as keyof ProfileEcogesture]
      );
    }
  }, [
    step,
    viewedStep,
    currentProfileType,
    answerType,
    isProfileTypeCompleted,
    isProfileEcogestureCompleted,
    profileEcogesture,
  ]);

  return (
    <>
      <div className="profile-form-container">
        <FormProgress step={step} formType="profile" />
        <div className="profile-question-label">
          {t(
            `profile_type.${ProfileTypeStepForm[step].toLowerCase()}.question`
          )}
        </div>
        {answerType.choices.map((value, index) => {
          if (value === null) return null;

          return (
            <label
              htmlFor={`answer-${index}`}
              key={index}
              className={classNames({
                ['radio_short']: answerType.choices.length < 5,
                ['radio_long']: answerType.choices.length > 4,
                ['answer-checked']: answer === value,
              })}
            >
              <input
                id={`answer-${index}`}
                type="radio"
                value={value.toString()}
                name={value.toString()}
                onChange={() => {
                  setAnswer(value);
                  navigationRef.current?.scrollIntoView({ behavior: 'smooth' });
                }}
                checked={answer === value ? true : false}
              />
              {t(
                `profile_type.${ProfileTypeStepForm[
                  step
                ].toLowerCase()}.${value}`
              )}
            </label>
          );
        })}
        <HelperText step={step} />
      </div>

      <FormNavigation
        navigationRef={navigationRef}
        step={step}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        disableNextButton={answer === '' || answer === undefined}
      />
    </>
  );
};

export default ProfileTypeFormSingleChoice;
