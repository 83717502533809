import classNames from 'classnames';
import FormNavigation from 'components/CommonKit/FormNavigation/FormNavigation';
import 'components/ProfileType/profileTypeForm.scss';
import { useTranslation } from 'react-i18next';
import { EcogestureStepForm } from 'enums';
import {
  ProfileEcogesture,
  ProfileEcogestureAnswer,
  ProfileEcogestureValues,
} from 'models';
import React, { useCallback, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import './ecogestureFormSingleChoice.scss';
interface EcogestureFormSingleChoiceProps {
  step: EcogestureStepForm;
  viewedStep: EcogestureStepForm;
  currentProfileEcogesture: ProfileEcogesture;
  answerType: ProfileEcogestureAnswer;
  setNextStep: (_profileEcogesture: ProfileEcogesture) => void;
  setPreviousStep: () => void;
}

const EcogestureFormSingleChoice = ({
  step,
  viewedStep,
  currentProfileEcogesture,
  answerType,
  setNextStep,
  setPreviousStep,
}: EcogestureFormSingleChoiceProps) => {
  const { t } = useTranslation();
  const { isProfileEcogestureCompleted } = useAppSelector(
    (state) => state.profile
  );
  const [answer, setAnswer] = useState<ProfileEcogestureValues>(
    isProfileEcogestureCompleted || step < viewedStep
      ? currentProfileEcogesture[answerType.attribute]
      : null
  );

  const handlePrevious = useCallback(() => {
    setPreviousStep();
  }, [setPreviousStep]);

  const handleNext = useCallback(() => {
    setNextStep({
      ...currentProfileEcogesture,
      [answerType.attribute]: answer,
    });
  }, [currentProfileEcogesture, setNextStep, answer, answerType.attribute]);

  return (
    <div className="ecogesture-profile-container">
      <div className="profile-form-container ecogesture-form-single">
        <div className="profile-question-label">
          {t(
            `ecogesture_form.${EcogestureStepForm[step].toLowerCase()}.question`
          )}
        </div>
        {answerType.choices.map((value, index) => {
          if (value === null) return null;
          return (
            <label
              htmlFor={`answer-${index}`}
              key={value.toString()}
              className={classNames({
                ['radio_short']: answerType.choices.length < 5,
                ['radio_long']: answerType.choices.length > 4,
                ['answer-checked']: answer === value,
              })}
            >
              <input
                id={`answer-${index}`}
                type="radio"
                value={value}
                name={value.toString()}
                onChange={() => setAnswer(value)}
                checked={answer === value}
                className={answer === value ? 'checked-input' : ''}
              />
              {t(
                `ecogesture_form.${EcogestureStepForm[
                  step
                ].toLowerCase()}.${value}`
              )}
            </label>
          );
        })}
      </div>
      <FormNavigation
        step={step}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        disableNextButton={answer === null}
        isEcogesture={true}
      />
    </div>
  );
};

export default EcogestureFormSingleChoice;
