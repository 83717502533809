import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useAuth } from 'react-oidc-context';

import './app.scss';
import 'styles/index.scss';

// components
import SplashRoot from 'components/Splash/SplashRoot';
import Navbar from 'components/Navbar/Navbar';
import AppRoutes from 'components/Routes/Routes';
import LoginPage from 'components/LoginPage/LoginPage';
import CookieBanner from 'components/CookieBanner/CookieBanner';
// store
import { setProfileKeycloak } from 'store/profileKeycloakUserSlice/profileKeycloakUserSlice.slice';
// hooks
import useFluidProvidersCallback from 'hooks/useFluidProvidersCallback';
import { useMatomoTracker } from 'hooks/useMatomoTracker';
import useUserInfosUpdater from 'hooks/useUserInfosUpdater';
import useTargetNavigationRedirect from 'hooks/useTargetNavigationRedirect';
import usePageTitle from 'hooks/usePageTitle';

export const App = () => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const providersRedirectUrl = useFluidProvidersCallback();
  const targetNavRedirectUrl = useTargetNavigationRedirect();
  const redirectUri = providersRedirectUrl ?? targetNavRedirectUrl;
  useMatomoTracker();
  useUserInfosUpdater();
  usePageTitle();

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent();
    });
  }, [auth.events, auth.signinSilent]);

  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      dispatch(setProfileKeycloak(auth.user));
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (auth.isAuthenticated && redirectUri) {
      auth.signinRedirect({ redirect_uri: redirectUri });
    }
  }, [auth.isAuthenticated, redirectUri]);

  if (!auth.isAuthenticated || auth.error) {
    return <LoginPage redirectUri={redirectUri} />;
  }

  return (
    <SplashRoot>
      <Navbar />
      <CookieBanner />
      <main className="app-content">
        <AppRoutes />
      </main>
    </SplashRoot>
  );
};

export default App;
